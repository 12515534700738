'use client';
import { Box, Flex } from '@chakra-ui/react';

import { BackgroundNoisePattern } from '@/components/BackgroundNoisePattern';
import { InnerwellLogo, LoginScreenGraphic } from '@/components/Images';

export const AuthLayout = ({ children }: { children: React.ReactNode }) => {
   return (
      <Flex
         flexDir={{ base: 'column', lg: 'row' }}
         bg="background.secondary"
         minH="var(--app-height)"
      >
         <Flex
            w={{ base: '100%', lg: '50%' }}
            bg="background.tertiary"
            alignItems="center"
            justifyContent="center"
            pos="relative"
            minH="56px"
         >
            <InnerwellLogo
               pos={{ base: 'relative', lg: 'absolute' }}
               top="0"
               left={{ lg: '50%' }}
               transform={{ lg: 'translateX(-50%)' }}
               fill="white"
               mt={{ lg: 8 }}
               w={{ base: '95px', lg: '143px' }}
            />
            <LoginScreenGraphic display={{ base: 'none', lg: 'block' }} />
         </Flex>
         <Flex
            w={{ base: '100%', lg: '50%' }}
            alignItems="center"
            justifyContent="center"
         >
            <BackgroundNoisePattern />
            <Box px={4} w="432px" py={10}>
               {children}
            </Box>
         </Flex>
      </Flex>
   );
};
